import { useRouter } from "next/router";
import { useContext, useEffect } from "react";
import { siteConfig } from "../configuration/config";
import { discountByCountryCodes } from "../configuration/promotion/discount-locations";
import { promotionCodes } from "../configuration/promotion/promotion-codes";
import { billingSelectionKey, promotionCodeIdKey } from "../pages/billing";
import { logger } from "../utils/logger";
import { useLocalStorage } from "./use-local-storage";
import { IpLocationContext } from "./use-location-detector";
import { usePlansLanding } from "./use-plans-landing";
import { useSessionStorage } from "./use-session-storage";
import { appSignInRedirect } from "../types/constants";

export function useLandingUtils() {
  const router = useRouter();
  const { setStorageData: setBillingSelection } = useLocalStorage<string>(billingSelectionKey);

  const ipLocation = useContext(IpLocationContext);

  const {
    storageData: promotionCodeId,
    setStorageData: setPromotionCodeId,
    isLoadingStorage: isLoadingPromotionCodeId,
  } = useSessionStorage<string>(promotionCodeIdKey);

  const { plans } = usePlansLanding({
    withCheckoutLink: false,
    promotionCodeId: promotionCodeId,
    currencyCode: ipLocation?.currencyCode,
  });

  useEffect(() => {
    if (isLoadingPromotionCodeId || promotionCodeId) {
      return;
    }

    const setPromotionCodeByName = (codeName: string) => {
      const promotionCode = promotionCodes.find(
        (code) => code.name.toLowerCase() === codeName.toLowerCase(),
      );

      logger.info(`Setting promotion code: ${promotionCode?.name} (${promotionCode?.percentage}%)`);
      setPromotionCodeId(promotionCode?.code);
    };

    const urlParams = new URLSearchParams(window.location.search);
    const urlPromotionCodeName = urlParams.get("pcode");

    if (urlPromotionCodeName) {
      setPromotionCodeByName(urlPromotionCodeName);
      return;
    }

    if (window.location.search.includes("cayspro")) {
      logger.info(`Setting promotion code for cayspro`);
      setPromotionCodeByName("TalkNow");
      return;
    }

    if (ipLocation?.countryCode && discountByCountryCodes.includes(ipLocation.countryCode)) {
      logger.info(`Setting promotion code for ${ipLocation.country}`);
      setPromotionCodeByName("TalkNow");
      return;
    }

    const isNovember = new Date().getMonth() === 10;

    if (isNovember) {
      setPromotionCodeByName("TimeToTalk");
    }
  }, [promotionCodeId, isLoadingPromotionCodeId, ipLocation, setPromotionCodeId]);

  const onSignupClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    logger.info("On Signup");
    router.push({
      pathname: "/authentication/signup",
      query: { forwardSignup: true },
    });
  };

  useEffect(() => {
    if (router && siteConfig.overrideLanding) {
      router.push(appSignInRedirect);
    }
  }, [router]);

  useEffect(() => {}, []);

  return { onSignupClick, setBillingSelection, promotionCodeId, products: plans?.products };
}
